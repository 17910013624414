.hip-icon {
	width: 20px;
}

.hip-item {
	padding: 10px 10px;
	font-weight: 700;
    box-sizing: border-box;
    min-height: 20px;
    line-height: 20px;
}

.hip-item-title {
	color: #337ab7;
}

.hip-item-description {
	color: #337ab7;
	font-weight: 400;
}
