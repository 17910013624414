@import "bootstrap/bootstrap";
@import "app/company.less";
@import "app/risc.less";
@import "app/hra.less";
@import "app/participant.less";
@import "app/hip.less";
@import "app/srhra.less";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
	font-weight: 300;
}

//Title shown in the header for anonymous layout
.mainheader-title {
    height: @navbar-height;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    color: #ffffff;
    padding: 0 15px;
    overflow: hidden;
    display: block;
}

.alerts-container {
  position: fixed;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
}

.ui-grid-container {
	margin-top: 10px;
	margin-bottom: 10px;
}

.ui-grid.no-rows {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.grid-msg-overlay .msg {
  opacity: 1;
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 50%;
  background-color: #eee;
  border-radius: 4px;
  border: 1px solid #555;
  text-align: center;
  font-size: 24px;
  display: table;
}

.grid-msg-overlay .msg span {
  display: table-cell;
  vertical-align: middle;
}

.cropArea {
    overflow: hidden;
    height:200px;
}
