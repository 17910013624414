.navbar-nav > .user-menu {
  > .dropdown-menu {
    // Header menu
    > li.user-header {
      // Company image
      > img.img-company {
        height: 70px;
        width: 165px;
        border: none;
      }
    }
  }
}

.main-header .company-logo {
  > .logo {
    padding: 0px 0px 0px 0px;
    > img {
      height: 50px;
      padding-bottom: 4px;
    }
  }
}
