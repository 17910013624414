/**  itemized progress tracker or bar - tiny progress tracker for assignments  **/

.pt-wizard {
    width:100%;margin-top: 0px;border-bottom: solid 1px #e0e0e0; padding: 0 0 5px 0;
}
.pt-item-wizard {
    text-align: center;
}

.pt-wizard > .pt-item-step .pt-item-dot {position: absolute; width: 21px; height: 21px; display: block; top: 15px; left: 50%; margin-top: -1px;  margin-left: -15px; border-radius: 50%;padding-top: 1px; background: #edf49f;}

.pt-wizard > .pt-item-step .pt-item-square {position: absolute; width: 20px; height: 20px; display: block; top: 25px; left: 50%; margin-top: -12px; margin-left: -15px; padding-top: 1px; background: #9ACD32}

.pt-wizard >.pt-item-step{padding: 0; position: relative;}

.pt-wizard >.pt-item-step .pt-item-info {color: #999; font-size: 12px;}

.pt-wizard > .pt-item-step.complete > .pt-item-dot {content: ''; background: #9ACD32; font-size: 14px;color:#fff;}

.pt-wizard > .pt-item-step.complete > .pt-item-dot:after{content: '\2713';background: #9ACD32;}
.pt-wizard > .pt-item-step.active > .pt-item-dot:after{content: '';background: #edf49f!important;}
.pt-wizard > .pt-item-step.disabled > .pt-item-dot:after {opacity: 0;}



.pt-wizard > .pt-item-step.complete > .pt-item-square{background: #9ACD32;}
.pt-wizard > .pt-item-step.active > .pt-item-square{background: #edf49f;}
.pt-wizard > .pt-item-step.disabled > .pt-item-square {opacity: 0;}


.pt-wizard > .pt-item-step > .pt-item-square.enabled {background: #ECAB51;}
.pt-wizard > .pt-item-step > .pt-item-square.enabled:after{content: '\f0d7';color:#ECAB51}



.pt-wizard > .pt-item-step > .pt-item-square:after {font-family: 'FontAwesome'; content: ''; width: 0px; height: 0px; background: #ECAB51; position: absolute; top: 10px; left: 4px; font-size: 16px;}


.pt-wizard > .pt-item-step > .progress {position: relative; border-radius: 0px; height: 5px; box-shadow: none;     margin: 20px 0;}

.pt-wizard > .pt-item-step.complete > .progress {background: #9ACD32}
.pt-wizard > .pt-item-step.active > .progress {background: #edf49f}
.pt-wizard > .pt-item-step.disabled > .progress {background: #b7b7b7}

.pt-wizard > .pt-item-step > .progress > .progress-bar {width:0px; box-shadow: none; background: #9ACD32;}

.pt-wizard > .pt-item-step:first-child > .progress > .progress-bar {width:0%;}
.pt-wizard > .pt-item-step:first-child  > .progress {left: 50%; width: 50%;}    
.pt-wizard > .pt-item-step:last-child > .progress > .progress-bar {width: 0%;}
.pt-wizard > .pt-item-step:last-child  > .progress {width: 50%;}

/** End of itemized progress tracker **/