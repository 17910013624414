

.whatif-toggle.collapsed:before {
    /* symbol for "collapsed" panels */
    content: "\f196";
}
.whatif-toggle:before {
    /* symbol for "opening" panels */
    font-family: 'FontAwesome';
    content: "\f147";
    float: left;
    vertical-align: middle;
    font-size:18px;
    cursor: pointer;
}

/* css for progress tracker */
.bs-wizard {margin-top: -45px;}
.bs-wizard-embed {margin-top: 0px; padding-left: 0px; padding-right: 0px;}

/*Form Wizard*/
.bs-wizard {border-bottom: solid 1px #e0e0e0; padding: 0 0 5px 0;}
.bs-wizard > .bs-wizard-step {padding: 0; position: relative;}
.bs-wizard > .bs-wizard-step + .bs-wizard-step {}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {color: #595959; font-size: 14px; margin-bottom: 5px;}
.bs-wizard > .bs-wizard-step .bs-wizard-info {color: #999; font-size: 12px;}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {position: absolute; width: 30px; height: 30px; display: block; top: 25px; left: 50%; margin-top: -15px; margin-left: -15px; border-radius: 50%;padding-top: 5px; background: #9ACD32;
text-align: -webkit-center;}

.bs-wizard > .bs-wizard-step.complete > .bs-wizard-dot {background: #9ACD32;}
.bs-wizard > .bs-wizard-step.active > .bs-wizard-dot {background: #edf49f;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {background-color: #b7b7b7;}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {content: ''; width: 0px; height: 0px; background: #9ACD32; border-radius: 50px; position: absolute; top: 0px; left: 6px; font-size: 22px;color:#fff;}

.bs-wizard > .bs-wizard-step.complete > .bs-wizard-dot:after{content: '\2713';}
.bs-wizard > .bs-wizard-step.active > .bs-wizard-dot:after{content: '';}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {opacity: 0;}

.bs-wizard > .bs-wizard-step > .bs-wizard-square { position: absolute; width: 30px; height: 30px; display: block; top: 25px; left: 50%; margin-top: -15px; margin-left: -15px; padding-top: 5px; background: #9ACD32;
text-align: -webkit-center;}

.bs-wizard > .bs-wizard-step > .bs-wizard-square:after {font-family: 'FontAwesome'; content: ''; width: 0px; height: 0px; background: #ECAB51; position: absolute; top: 18px; left: 9px; font-size: 22px;}

.bs-wizard > .bs-wizard-step > .bs-wizard-square.enabled {background: #ECAB51 !important;}
.bs-wizard > .bs-wizard-step.active > .bs-wizard-square {background: #edf49f;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-square {background-color: #b7b7b7;}

.bs-wizard > .bs-wizard-step > .bs-wizard-square.enabled:after{content: '\f0d7';color:#ECAB51}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-square:after {opacity: 0;}

.bs-wizard > .bs-wizard-step > .progress {position: relative; border-radius: 0px; height: 8px; box-shadow: none;     margin: 20px 0;}
.bs-wizard > .bs-wizard-step.active > .progress {background: #edf49f}
.bs-wizard > .bs-wizard-step.disabled > .progress {background: #b7b7b7}

.bs-wizard > .bs-wizard-step > .progress > .progress-bar {width:0px; box-shadow: none; background: #9ACD32;}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {width:100%;}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {width:0%;}

.bs-wizard > .bs-wizard-step:first-child > .progress > .progress-bar {width:0%; background: #9ACD32;}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {width: 100%;}

.bs-wizard > .bs-wizard-step:first-child  > .progress {left: 50%; width: 50%;background: #9ACD32;}
.bs-wizard .bs-wizard-embed > .bs-wizard-step:first-child  > .progress {left: 0%; width: 100%;background: #9ACD32;}

.bs-wizard > .bs-wizard-step:first-child.complete  > .progress {left: 50%; width: 50%;background: #9ACD32;}
.bs-wizard > .bs-wizard-step:first-child.active  > .progress {left: 50%; width: 50%;background: #edf49f;}
.bs-wizard > .bs-wizard-step:first-child.disabled  > .progress {left: 50%; width: 50%;background: #b7b7b7;}

.bs-wizard .bs-wizard-embed > .bs-wizard-step:first-child.complete  > .progress {left: 0%; width: 100%;background: #9ACD32;}
.bs-wizard .bs-wizard-embed > .bs-wizard-step:first-child.active  > .progress {left: 0%; width: 100%;background: #edf49f;}
.bs-wizard .bs-wizard-embed > .bs-wizard-step:first-child.disabled  > .progress {left: 0%; width: 100%;background: #b7b7b7;}

.bs-wizard > .bs-wizard-step:last-child  > .progress {width: 50%;background: #9ACD32;}
.bs-wizard .bs-wizard-embed > .bs-wizard-step:last-child  > .progress {width: 100%;background: #9ACD32;}

.bs-wizard > .bs-wizard-step:last-child.complete  > .progress {width: 50%;background: #9ACD32;}
.bs-wizard > .bs-wizard-step:last-child.active  > .progress {width: 50%;background: #edf49f;}
.bs-wizard > .bs-wizard-step:last-child.disabled  > .progress {width: 50%;background: #b7b7b7;}
.bs-wizard > .bs-wizard-step:last-child.complete  > .progress  > .progress-bar  {width: 50%;background: #9ACD32;}
.bs-wizard > .bs-wizard-step:last-child.active  > .progress > .progress-bar  {width: 50%;background: #edf49f;}
.bs-wizard > .bs-wizard-step:last-child.disabled  > .progress > .progress-bar  {width: 50%;background: #b7b7b7;}

.bs-wizard .bs-wizard-embed > .bs-wizard-step:last-child.complete  > .progress {width: 100%;background: #9ACD32;}
.bs-wizard .bs-wizard-embed > .bs-wizard-step:last-child.active  > .progress {width: 100%;background: #edf49f;}
.bs-wizard .bs-wizard-embed > .bs-wizard-step:last-child.disabled  > .progress {width: 100%;background: #b7b7b7;}
.bs-wizard .bs-wizard-embed > .bs-wizard-step:last-child.complete  > .progress  > .progress-bar  {width: 100%;background: #9ACD32;}
.bs-wizard .bs-wizard-embed > .bs-wizard-step:last-child.active  > .progress > .progress-bar  {width: 100%;background: #edf49f;}
.bs-wizard .bs-wizard-embed > .bs-wizard-step:last-child.disabled  > .progress > .progress-bar  {width: 100%;background: #b7b7b7;}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot{ pointer-events: none; }
/*END Form Wizard*/
