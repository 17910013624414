// Color variables (appears count calculates by raw css)
@color0: #cccccc; // Appears 2 times


.btn-secondary {
	background-color: @color0;
	border-color: @color0;
	color: black;
}

label.active {
	background-color: #2aabd2;
}